import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const themeList = [
  {
    id: "1",
    image: "/images/themes/1.png",
    title: "Insider Knowledge",
    text: "Proprietary insights and strategies.",
  },
  {
    id: "2",
    image: "/images/themes/2.png",
    title: "Leading Strategies",
    text: "Industry best practices from F500 executives.",
  },
  {
    id: "3",
    image: "/images/themes/3.png",
    title: "Peer Networking",
    text: "Build valuable connections with fellow TA executives.",
  },
  {
    id: "4",
    image: "/images/themes/4.png",
    title: "TA Innovations",
    text: "Learn, share, and engage on the latest in TA.",
  },
];
export default function Themes() {
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });
  return (
    <section className="themes" ref={ref}>
      <div className="auto__container">
        <div className="themes__inner">
          <div className="themes__inner-title">
            <motion.div
              initial={{ opacity: 0 }} // Set your initial state
              animate={inView ? { opacity: 1 } : {}} // Set your animation properties
              transition={{
                duration: 0.9,
                ease: "easeOut",
              }}
            >
              <h3>KEY THEMES</h3>
              <p>
                The ZipRecruiter Leadership Summit agenda is thoughtfully
                designed to offer:
              </p>
            </motion.div>
          </div>
          <div className="themes__inner-row">
            {themeList.map((item, index) => {
              return (
                <a href="#" className="themesItem" key={index}>
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, scale: 0 }} // Set your initial state
                    animate={inView ? { opacity: 1, scale: 1 } : {}} // Set your animation properties
                    transition={{
                      duration: 0.9,
                      ease: "easeOut",
                      delay: index * 0.2,
                    }}
                  >
                    <div className="themesItem__image">
                      <img
                        src={process.env.PUBLIC_URL + item.image}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="themesItem__content">
                      <h6>{item.title}</h6>
                      <div className="theme-desc-container">
                        <p className="sm">{item.text}</p>
                      </div>
                    </div>
                  </motion.div>
                </a>
              );
            })}
          </div>
          <div className="reg-btn-container">
            <a
              href="https://cvent.me/1eReq3"
              target="_blank"
              className="button primary"
            >
              REGISTER NOW
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
